import React, { useEffect, useState } from "react";
import API from "../../../../API/API";
import ProductTable from "./ProductTable";

export default function ProductInventory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [productList, setProductList] = useState([]);

  const fetchProductList = async () => {
    try {
      const response = await API.get(
        `/inventory/product/components?page=${currentPage}`
      );
      const fetchedProducts = response.products;
      // Combine items with same name, cartoon, and color
      const combinedProducts = Object.values(
        fetchedProducts.reduce((acc, item) => {
          const key = `${item.box.name}_${item.cartoon.cartoonOption}_${item.color.name}`;
          if (!acc[key]) {
            acc[key] = { ...item, quantity: 0 };
          }
          acc[key].quantity += Number(item.quantity);
          return acc;
        }, {})
      );

      console.log(combinedProducts);

      setProductList(combinedProducts);
      setCurrentPage(currentPage);
      setTotalPages(response.total_pages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, [currentPage]);

  return (
    <>
      <ProductTable
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        productList={productList}
      />
    </>
  );
}
